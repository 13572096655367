var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    return __async(this, null, function* () {
      console.log("hidden_with_localstorage_value init", this.localStorageFieldValue);
    });
  }
  connect() {
    return __async(this, null, function* () {
      console.log("hidden_with_localstorage_value connect", this.localStorageFieldValue);
      yield this.setLocalStorageToInputValue();
      window.addEventListener("storage", (e) => __async(this, null, function* () {
        console.log("storage updated", e);
        yield this.setLocalStorageToInputValue();
      }));
    });
  }
  setLocalStorageToInputValue() {
    return __async(this, null, function* () {
      console.log("hidden_with_localstorage_value this.localStorageFieldValue", this.localStorageFieldValue);
      console.log("hidden_with_localstorage_value this.inputTarget", this.inputTarget);
      const localStorageValue = localStorage.getItem(this.localStorageFieldValue);
      yield Promise.resolve().then(() => {
        var _a, _b;
        console.log("hidden_with_localstorage_value localStorageValue", localStorageValue);
        if (localStorageValue) {
          this.inputTarget && ((_a = this.inputTarget) == null ? void 0 : _a.setAttribute("value", `${localStorageValue}`));
        } else {
          this.inputTarget && ((_b = this.inputTarget) == null ? void 0 : _b.setAttribute("value", "false"));
        }
      });
    });
  }
}
__publicField(component_controller_default, "targets", ["input"]);
__publicField(component_controller_default, "values", {
  localStorageField: String
});
