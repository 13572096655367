var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    console.log("Answer::RadioButtons::ComponentController[index]", this.element);
  }
  connect() {
    console.log("Answer::RadioButtons::ComponentController[connect]", this.element);
  }
  handleAnswer(event) {
    event.preventDefault();
    this.clearAnswers();
    const discEl = event.target.querySelector(".answer-disc");
    discEl.classList.add(this.activeClass);
    this.answerController.getAnswerData(event.target);
    this.answerController.handleAnswer();
  }
  clearAnswers() {
    const discEls = [...this.element.querySelectorAll(".answer-disc")];
    discEls.forEach((discEl) => {
      discEl.classList.remove(this.activeClass);
    });
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
__publicField(component_controller_default, "classes", ["active"]);
