var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    console.log("Answer::Dropdown::ComponentController[index]", this.element);
  }
  connect() {
    console.log("Answer::Dropdown::ComponentController[connect]", this.element);
  }
  handleAnswer(event) {
    event.preventDefault();
    console.log("Answer::Dropdown::ComponentController#handleSelectQuestion]", event);
    const options = event.target.options;
    const answer = options[options.selectedIndex];
    console.log("ANSWR:::DDD", answer);
    this.answerController.getAnswerData(answer);
    this.answerController.handleAnswer();
  }
  handleSubmit(event) {
    event.preventDefault();
    console.log("Answer::Dropdown::ComponentController#handleSubmit]", event);
    const options = this.dropdownTarget.options;
    const answer = options[options.selectedIndex];
    console.log("ANSWR:::DDD", answer);
    if (this.dropdownTarget.reportValidity()) {
      this.answerController.getAnswerData(answer);
      this.answerController.handleAnswer();
    }
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
__publicField(component_controller_default, "targets", ["dropdown"]);
