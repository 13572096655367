var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, { enumerable: true, configurable: true, writable: true, value }) : obj[key] = value;
var __publicField = (obj, key, value) => {
  __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
  return value;
};
import ApplicationController from "@/controllers/application_controller";
export default class component_controller_default extends ApplicationController {
  initialize() {
    console.log("Answer::TileButtons::ComponentController[index]", this.element);
    this.initButtonsOverlay();
  }
  connect() {
    console.log("Answer::TileButtons::ComponentController[connect]", this.element);
  }
  handleAnswer(event) {
    event.preventDefault();
    this.answerController.getAnswerData(event.target);
    this.answerController.handleAnswer();
  }
  initButtonsOverlay() {
    const classes = "relative after:content-[''] after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0 after:bg-transparent";
    const tiles = [...this.tileTargets];
    tiles.map((tile) => {
      tile.classList.add(...classes.split(" "));
    });
  }
  get answerController() {
    return this.application.getControllerForElementAndIdentifier(this.element, "chain--answer--component");
  }
}
__publicField(component_controller_default, "targets", ["tile"]);
